<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left"></div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right"></div>
    </div>
    <div
      style="
        font-size: 32px;
        text-align: left;
        color: #e51550;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        font-weight: 400;
        margin-bottom: 25px;
      "
    >
      Beschäftigungsmodelle
    </div>

    <div style="max-width:900px; margin: 0 auto;">
      <table class="table">
        <tr>
          <th>Nummer</th>
          <th>Name</th>
          <th>Farbe</th>
          <th>Standard Beschäftigungsmodell</th>
          <th>in Verwendung</th>
          <th>Status</th>
          <th>Bearbeiten</th>
          <th>De-/Aktivieren</th>
        </tr>
        <tr v-for="x in employmentModels" :key="x.id"  :style="{ backgroundColor: x.isActive ? 'transparent' : '#d3d3d3' }"  style="margin:0px;">
          <td style="padding:1px; font-size:14px;">{{ x.number }}</td>
          <td style="padding:1px; font-size:14px;">{{ x.name }}</td>
          <td style="display: flex; align-items: center; align-content: center; padding:4px 0px 0px 0px;">
            <div
              style="
                width: 15px;
                height: 15px;
                background-color: #f00;
                text-align: center;
                cursor: pointer;
                margin: 0 auto;
              "
              v-bind:style="{ 'background-color': x.color }"
              v-on:click="toggleColorPicker(x)"
            ></div>
          </td>
          <td style="padding:1px; font-size:14px;"><i class="fas fa-check-circle checkbox" v-bind:class="{false: !x.isDefault}" v-on:click="toggleDefaultEmploymentModel(x)"></i></td>
          <td style="padding:1px; font-size:14px;">{{ x.usage }}</td>
          <td style="padding:1px; font-size:14px; text-align: center;">
              <span v-if="x.isActive" class="text-success">
                  aktiv
              </span>
              <span v-else class="text-secondary">
                  inaktiv
              </span>
          </td>
          <td style="padding:1px; font-size:14px;">
            <i class="fas fa-edit" @click="openEditEntity(x)"></i>
          </td>
          <td style="padding:1px;">
            <button v-if="x.isActive && x.usage == 0"
                    class="btn btn-warning"
                    style="padding: 1px 5px; font-size: 10px; height: 16px; margin-top:-5px; line-height: 13px;"
                    @click="updateEmploymentModel(x, 'toggleActive')">
              Deaktivieren
            </button>
            <button v-else-if="!x.isActive"
                    class="btn btn-success"
                    style="padding: 1px 5px; font-size: 10px; height: 16px; margin-top:-5px; line-height: 13px;"
                    @click="updateEmploymentModel(x, 'toggleActive')">
              Aktivieren
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import button from "../uicomponents/button.vue";
export default {
  components: { button },
  name: "EmploymentModels",
  data() {
    return {
      loading: true,
      saves: false,
      employeesFull: [],
      employmentModels: [],
    };
  },
  created: function () {
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  mounted: function () {},
  computed: {
    ...mapGetters(["companyId"]),
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-refresh",
        click: () => {
          view.refresh();
        },
        tooltip: "Daten neu laden",
      });
      state.buttons.push({
        type: "block",
        icon: "fa-plus",
        click: ($event) => {
          view.openNewEmploymentModel($event);
        },
        tooltip: "Neu anlegen",
      });

      return state;
    },
    authorizationHeader() {
      return { 'Authorization': 'Bearer ' + this.$store.state.jwt };
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.loading = true;
      view.loadEmploymentModels();
    },

    loadEmployees: async function () {
      const url = '/api/sec/employee/coredata';
      const params = {
        companyId: this.$store.state.companyId,
        basicData: 0,
      };
      const response = await this.axios({
        method: 'GET',
        url,
        params,
        headers: this.authorizationHeader,
      });
      this.employeesFull = response.data.data;
      this.countEmploymentModelUsage();
      this.$forceUpdate();
    },
    loadEmploymentModels: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      view.$helpers
        .GetCall("/api/sec/employmentModels", params)
        .then((response) => {
          let data = response.data.data;
          let tmp = [];
          for (let i = 0; i < data.length; i++) {
            let item = {
              id: data[i].id,
              number: data[i].number,
              name: data[i].name,
              sorting: data[i].sorting,
              color: data[i].color,
              isActive: data[i].isActive,
              isDefault: data[i].isDefault
            };
            tmp.push(item);
          }
          view.employmentModels = tmp;
          view.loadEmployees();
        })
        .catch((e) => {
          view.loading = false;
          console.log(e);
        });
    },
    countEmploymentModelUsage: function () {
      this.employmentModels.forEach(model => {
        model.usage = 0;
      });
      const modelsIndex = {};
      this.employmentModels.forEach(model => {
        modelsIndex[model.id] = model;
      });
      this.employeesFull.forEach(employee => {
        if (employee.employmentModelId && modelsIndex.hasOwnProperty(employee.employmentModelId)) {
          modelsIndex[employee.employmentModelId].usage++;
        }
      });
    },

    openNewEmploymentModel(event) {
      let view = this;
      let dataEntries = [
        {
          label: "Schlüssel: ",
          key: "number",
          hidden: false,
          type: "numeric",
          value: 0,
          min: 0,
          max: 65535,
          styles: { width: "350px" },
        },
        {
          label: "Bezeichnung: ",
          key: "name",
          hidden: false,
          type: "text",
          value: "",
          length: 128,
          styles: { width: "350px" },
        },
        //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: 1, slider: {value: 1, min: 1, max: 100}},
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (newFIBU) {
            view.createFinancialAccountingGroup(newFIBU);
          },
          errorCheck: {
            number: (entries) => {
              if (
                entries.hasOwnProperty("number") &&
                view.employmentModels.find(
                  (f) => parseInt(f.number) === parseInt(entries.number)
                )
              )
                return {
                  key: "number",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
            name: (entries) => {
              if (
                entries.hasOwnProperty("name") &&
                view.employmentModels.find((f) => f.name === entries.name)
              )
                return {
                  key: "name",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
          },
        },
        header: "Neues Beschäftigungsmodell",
        remainAfterSelect: false,
        expanded: true,
      });
    },
    openEditEntity(entity) {
      let view = this;

      let dataEntries = [
        {
          label: "id: ",
          key: "id",
          hidden: true,
          type: "text",
          value: entity.id,
          length: 128,
          styles: { width: "350px" },
        },
        {
          label: "Nummer: ",
          key: "number",
          hidden: false,
          type: "numeric",
          value: entity.number,
          min: 0,
          max: 65535,
          styles: { width: "350px" },
        },
        {
          label: "Name: ",
          key: "name",
          hidden: false,
          type: "text",
          value: entity.name,
          length: 128,
          styles: { width: "350px" },
        },
        //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: fibu.sorting, slider: {value: fibu.sorting, min: 1, max: 100}},
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (eEntity) {
            view.updateEmploymentModel(eEntity,'edit');
          },
          errorCheck: {
            number: (entries) => {
              if (
                entries.hasOwnProperty("number") &&
                view.employmentModels.find(
                  (f) => f.number !== entity.number && f.number === entries.number
                )
              )
                return {
                  key: "number",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
            name: (entries) => {
              if (
                entries.hasOwnProperty("name") &&
                view.employmentModels.find(
                  (f) => f.name !== entity.name && f.name === entries.name
                )
              )
                return {
                  key: "name",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
          },
        },
        header: "Beschäftigungsmodell bearbeiten",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openDeleteFinancialAccountingGroup(fibu) {
      let view = this;

      let dataEntries = [
        {
          label: "id: ",
          key: "id",
          hidden: true,
          type: "text",
          value: fibu.id,
          length: 128,
          styles: { width: "350px" },
        },
        {
          label: "",
          key: "number",
          hidden: false,
          type: "output",
          value: "Soll das Element wirklich gelöscht werden?",
        },
        {
          label: "Name: ",
          key: "name",
          hidden: false,
          type: "output",
          value: fibu.name,
          length: 128,
          styles: { width: "350px" },
        },
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          positiveButtonLabel: "Löschen",
          entries: dataEntries,
          selectCallback: function (eFibu) {
            view.deleteFinancialAccountingGroup(eFibu);
          },
        },
        header: "Beschäftigungsmodell löschen",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },

    createFinancialAccountingGroup(newFIBU) {
      let view = this;
      let url = "/api/sec/employmentModels";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/employmentModels"
      );
      let payload = {
        companyId: this.$store.state.companyId,
        number: newFIBU.number,
        name: newFIBU.name,
        priority: 0, //newFIBU.priority,
      };
      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success(
            "Beschäftigungsmodell erstellt",
            "Beschäftigungsmodell erstellt"
          );
          view.refresh(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updateEmploymentModel(model, updateType) {
      let view = this;
      let payload = {};
      let url = "/api/sec/employmentModels/" + model.id;
      if (updateType === 'toggleActive') {
        payload = {
          companyId: view.$store.state.companyId,
          isActive: !model.isActive
        };
      } else if (updateType === 'edit') {
        payload = {
          companyId: view.$store.state.companyId,
          number: model.number,
          name: model.name,
          priority: 0, // model.priority,
        };
      }
      url = view.$helpers.appendParam("companyId", view.$store.state.companyId, url);


      view.axios({
        method: "put",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
      .then((response) => {
        view.$helpers.success(
          "Beschäftigungsmodell geändert",
          "Beschäftigungsmodell geändert"
        );
        if (updateType === 'edit') {
          view.refresh(true);
        }else if (updateType === 'toggleActive') {
          model.isActive = !model.isActive;
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 422) {
          let message = "Fehler beim Ändern des Beschäftigungsmodells";
          let bodyMessage = "...";
          if (error.response.data && error.response.data.message && error.response.data.message == "The EmploymentModel is still in use by employees"){
            bodyMessage = "Das Beschäftigungsmodell wird noch von Mitarbeitern verwendet.";
          }
          if (error.response.data && error.response.data.message && error.response.data.message == "This name is already taken by another entity"){
            bodyMessage = "Der Name wird bereits von einem anderen Beschäftigungsmodell verwendet.";
          }
          if (error.response.data && error.response.data.message && error.response.data.message == "This number is already taken by another entity"){
            bodyMessage = "Die Nummer wird bereits von einem anderen Beschäftigungsmodell verwendet.";
          }
          view.$helpers.error(message, bodyMessage);
        } else {
          view.$helpers.error(
            "Fehler beim Ändern des Beschäftigungsmodells",
            "Fehler beim Ändern des Beschäftigungsmodells"
          );
        }
      });
    },
    toggleDefaultEmploymentModel(editModel){
      let view = this;

      if (editModel.isDefault) {
        let url = '/api/sec/employmentModels/'+editModel.id;
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employmentModels/'+editModel.id);
        let payload = {
          companyId: this.$store.state.companyId,
          isDefault: false,
        }
        this.axios({
          method: 'put',
          headers: {
              'AUTHORIZATION': "Bearer "+view.$store.state.jwt
          },
          url: url,
          data: payload
        }).then(response => {
            view.$helpers.success('Beschäftigungsmodell geändert', 'Beschäftigungsmodell geändert');
            view.refresh(true);
        }).catch(function (error) {
            console.log(error)
        });
      } else {
        let promises = [];

        let url = '/api/sec/employmentModels/'+editModel.id;
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employmentModels/'+editModel.id);
        let payload = {
          companyId: this.$store.state.companyId,
          isDefault: true,
        }
        promises.push(
          this.axios({
            method: 'put',
            headers: {
                'AUTHORIZATION': "Bearer "+view.$store.state.jwt
            },
            url: url,
            data: payload
          })
        );
        view.employmentModels.forEach(model => {
          if (model.id !== editModel.id && model.isDefault) {
            let url = '/api/sec/employmentModels/'+model.id;
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employmentModels/'+model.id);
            let payload = {
              companyId: this.$store.state.companyId,
              isDefault: false,
            }
            promises.push(
              this.axios({
                method: 'put',
                headers: {
                    'AUTHORIZATION': "Bearer "+view.$store.state.jwt
                },
                url: url,
                data: payload
              })
            );
          }
        });
        Promise.all(promises).then(response => {
            view.$helpers.success('Beschäftigungsmodelle geändert', 'Beschäftigungsmodelle geändert');
            view.refresh(true);
        }).catch(function (error) {
            console.log(error)
        });
      }
    },

    toggleColorPicker(fibu, val = null) {
      let view = this;
      this.$helpers.OpenColorSelection(
        (color) => {
          fibu.color = color;
          view.saveColor(fibu);
        },
        {
          colorSelected: fibu.color,
          colorBlacklist: this.employmentModels.map((s) => {
            return { color: s.color, label: s.name };
          }),
        }
      );
    },
    saveColor(myObj) {
      let view = this;
      let url = "/api/sec/employmentModels/" + myObj.id;
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/employmentModels/" + myObj.id
      );
      let payload = {
        companyId: this.$store.state.companyId,
        color: myObj.color,
      };
      this.axios({
        method: "put",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success("Farbe geändert", "Farbe geändert");
          view.refresh(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
.name-filter .fa-search {
  color: var(--contrast-4);
  position: absolute;
  top: 5px;
  left: 5px;
}

.half-width {
  width: 50%;
}

.fix-button-width {
  width: 150px;
}

.filter-input {
  margin-left: -45px;
  padding-left: 40px;
  width: calc(100% - 25px);
}

.list {
  width: calc(100% - 78px);
  margin: auto;
  height: 265px;
  box-shadow: inset 0 0 0 1px var(--contrast-3);
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
}
.list .scroll-content {
  padding: 5px;
  overflow-y: auto;
  height: 265px;
}

.list-entry {
  height: 35px;
  margin-bottom: 2px;
  border-radius: 100px;
  cursor: pointer;
}

.list-entry:hover {
  background: var(--sub-menu);
  cursor: pointer;
}
.list-entry.selected {
  background: #e51550;
  cursor: pointer;
}
.list-entry.selected > .label,
.list-entry:hover > .label {
  color: var(--bg);
  cursor: pointer;
}
.list-entry.selected > .label > .label-small,
.list-entry.hover > .label > .label-small {
  color: var(--bg) !important;
  cursor: pointer;
}

.list-entry > .label {
  float: left;
  font-size: 14px;
  font-family: DistrictProBook;
  color: var(--contrast-4);
  padding-top: 6px;
  cursor: pointer;
}
.list-entry > .label > .label-small {
  cursor: pointer;
  color: var(--contrast-5);
}

.picture {
  float: left;
  border-radius: 100px;
  background: var(--contrast-1);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.position-right {
  float: right;
  margin: 0;
  margin-right: -5px;
}
.pictureImg {
  border-radius: 100px;
  height: 25px;
  width: 25px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--contrast-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--contrast-3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}
.has-header > .has-header > .scrollbar-container {
  height: calc(100% - var(--header-height) - var(--tab-menu-height)) !important;
  overflow-x: hidden;
  top: var(--header-height);
  padding: 0px 14px;
}
</style>
